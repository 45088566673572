/*================*** Header Section Start Here     ***===================*/

.header-section {
  background: rgba($primary-color, $alpha: 1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  @extend %abs;
  @extend %w-100;
  z-index: 999;

  &.header-fixed {
    position: fixed;
    background-color: $body-color;
    @extend %w-100;
    top: 0;
    left: 0;
    animation: fadeInDown 0.5s;
    -webkit-animation: fadeInDown 0.5s;
    -moz-animation: fadeInDown 0.5s;
    box-shadow: 0 0 10px rgba($color: $black-color, $alpha: 0.5);
    border-bottom: 1px solid rgba($white-color, $alpha: 0.1);
    z-index: 999;

    @include breakpoint(lg) {
      top: -48px;
    }

    @include breakpoint(xl) {
      top: -55px;
    }

    .header-holder {
      @extend %rel;
    }

    .logo {
      @extend %abs;

      @include breakpoint(xl) {
        max-width: 90px;
      }

      max-width: 80px;
      left: 0;
      bottom: 10px;
    }
  }
  &.style2 {
    .header-menu-part {
      @include breakpoint(lg) {
        width: 100%;
        &::after {
          display: none;
        }
      }
    }
  }
}

.header-menu-part {
  @extend %w-100;
  @extend %rel;

  @include breakpoint(lg) {
    width: 75%;

    &::after {
      position: absolute;
      content: "";
      top: -20px;
      left: -15px;
      height: 120%;
      width: 1px;
      background-color: rgba($color: $white-color, $alpha: 0.1);
      transform: rotate(30deg);
    }
  }
}

.header-top {
  border-bottom: 1px solid rgba($white-color, $alpha: 0.1);

  * {
    color: $white-color;
  }

  @include breakpoint(max-lg) {
    display: none;
    background-color: rgba($primary-color, $alpha: 0.95);
  }
  &.open {
    display: block;
  }
}

.header-top-area {
  @extend %flex;
  @extend %justify-between;
  @extend %align-items-center;

  .social-icons {
    @extend %m-0;

    li {
      @extend %p-0;
      @extend %rel;

      &::after {
        @extend %abs;
        height: 120%;
        width: 1px;
        background-color: rgba($color: $white-color, $alpha: 0.1);
        right: 0;
        top: -5px;
        transform: rotate(25deg);
      }

      &:last-child {
        @include breakpoint(max-lg) {
          &::after {
            display: none;
          }
        }
      }

      a {
        @extend %d-inline-block;
        height: 50px;
        line-height: 50px;
        width: 50px;
        background: transparent;
        @extend %text-center;
      }

      p {
        @extend %mb-0;
      }

      &:last-child {
        right: -12px;
      }
    }
  }

  .left {
    @extend %flex;
    margin: 0 -25px;

    li {
      padding: 15px 25px;

      &:first-child {
        @extend %rel;
        @extend %ml-30;

        @include breakpoint(sm) {
          &::after {
            position: absolute;
            content: "";
            height: 120%;
            width: 1px;
            background-color: rgba($color: $white-color, $alpha: 0.1);
            right: 0;
            top: -7px;
            transform: rotate(25deg);
          }
        }
      }

      i {
        @extend %mr-5;
      }
    }
  }

  @include breakpoint(max-xl) {
    .left {
      li {
        font-size: 14px;
        padding: 9px 15px;
      }
    }

    .social-icons {
      li {
        a {
          height: 46px;
          line-height: 46px;
          width: 46px;
        }
      }
    }
  }

  @include breakpoint(max-lg) {
    .left {
      width: 100%;
      text-align: center;
      justify-content: center;
      margin: 0;
      border: 1px solid rgba($white-color, $alpha: 0.1);

      li {
        flex-grow: 1;
        border-top: 1px solid rgba($white-color, $alpha: 0.1);
        border-right: none;

        &:first-child {
          border-top: none;
        }
      }
    }

    .social-icons {
      width: 100%;
      text-align: center;
      justify-content: center;
      margin: 0;
      border-bottom: 1px solid rgba($white-color, $alpha: 0.1);

      li {
        flex-grow: 1;
      }
    }
  }
}

.header-wrapper {
  padding: 19px 0;
  @extend %flex;
  @extend %justify-between;
  @extend %align-items-center;
  @include breakpoint(max-lg) {
    width: 100%;
  }
  @include breakpoint(sm) {
    position: relative;
  }

  .logo {
    a {
      @extend %d-block;

      img {
        max-width: 100%;
      }
    }

    @include breakpoint(max-sm) {
      width: 160px;
    }

    @include breakpoint(max-xl) {
      @include breakpoint(lg) {
        width: 200px;
      }
    }
  }

  .menu-area {
    @extend %flex;
    @extend %align-items-center;

    .login,
    .signup {
      display: none;
      @extend %rel;
      @include breakpoint(md) {
        padding: 10px;
        display: inline-block;
      }

      @include breakpoint(xl) {
        padding: 15px;
      }
      span {
        z-index: 1;
        @extend %rel;
      }
    }

    .login {
      @extend %bg-white;
      color: $theme-color;
      border-radius: 2px 0 0 2px;
      @include transition($transition);
      &:before {
        @extend %abs;
        bottom: 0;
        left: 1px;
        height: 2px;
        width: 100%;
        background: $theme-color;
        @include transition($transition);
      }
      // &:hover {
      //     background: $theme-color;
      //     color: $white-color;
      //     &::before {
      //         background: $white-color;
      //     }
      // }
    }

    .signup {
      @extend %bg-theme;
      color: $white-color;
      border-radius: 0 2px 2px 0;
      &:before {
        @extend %abs;
        bottom: 0;
        left: 1px;
        height: 2px;
        width: 100%;
        background: $white-color;
        border-radius: 2px;
      }
      &:after {
        @extend %abs;
        bottom: 0;
        right: -1px;
        width: 2px;
        height: calc(100% - 2px);
        background: $white-color;
      }
      // &:hover {
      //     background: $white-color;
      //     color: $theme-color;
      //     &::before, &::after {
      //         background: $theme-color;
      //     }
      // }
    }

    .ellepsis-bar {
      font-size: 20px;
      @extend %ml-20;

      i {
        @extend %pointer;
        color: $white-color;
      }
    }

    @include breakpoint(max-sm) {
      padding: 15px 0;

      .ellepsis-bar {
        margin-left: 20px;
      }
    }
  }
}

.mobile-logo {
  max-width: 80px;
}

.menu {
  @extend %flex;

  @include breakpoint(lg) {
    margin-right: 30px;
    margin-bottom: 0;
  }

  > li {
    > a {
      color: $white-color;
      font-size: $fs-base;
      font-weight: 700;
      padding: 15px 22px;
      text-transform: uppercase;

      i {
        margin-right: 5px;
      }
    }
  }

  @include breakpoint(lg) {
    li {
      position: relative;

      .submenu {
        position: absolute;
        top: calc(100%);
        left: 0;
        display: block;
        -webkit-transition: all ease 0.3s;
        -moz-transition: all ease 0.3s;
        transition: all ease 0.3s;
        z-index: 9;
        background: #ffffff;
        width: 220px;
        padding: 0;
        -webkit-transform: translateY(35px);
        -ms-transform: translateY(35px);
        transform: translateY(35px);
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.18);
        border: none;

        li {
          padding: 0;

          a {
            padding: 10px 20px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            color: $black-color;
            font-weight: 500;
            border-bottom: 1px solid $border-color;

            &:hover,
            &.active {
              background: $theme-color;
              padding-left: 22px;
              color: $white-color;
            }
          }

          &:last-child {
            a {
              border-bottom: none;
            }
          }

          .submenu {
            left: 100%;
            top: 0;
          }
        }
      }

      &:hover {
        > .submenu {
          -webkit-transform: translateY(0px);
          -ms-transform: translateY(0px);
          transform: translateY(0px);
          opacity: 1;
          visibility: visible;
          a {
            &::before {
              right: 14px;
            }
            &::after {
              right: 10px;
            }
            &::after,
            &::before {
              background: $black-color;
            }
            &:hover {
              &::after,
              &::before {
                background: $white-color;
              }
            }
          }
          .submenu {
            transform: translateY(0px);
          }
        }
      }
    }

    @include breakpoint(max-xl) {
      li {
        a {
          padding: 10px 12px;
        }
      }
    }
  }

  @include breakpoint(max-lg) {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    overflow: auto;
    max-height: 400px;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: top;

    //glassmorphism
    background: rgba($primary-color, $alpha: 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);

    li {
      width: 100%;
      padding: 0;

      a {
        display: block;
        padding: 10px 25px;
        font-size: 15px;
        font-weight: 700;
        text-transform: capitalize;
        border-top: 1px solid rgba($white-color, $alpha: 0.1);
        color: $white-color;

        &:hover {
          background: rgba($theme-color, $alpha: 0.6);
        }
      }

      .submenu {
        padding-left: 20px;
        // display: block;
        background-color: transparent;
        position: static !important;
        transform: translate3d(0px, 0px, 0px) !important;
        li {
          width: 100%;

          a {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    &.active {
      -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
      transform: scaleY(1);
    }
  }
}

.header-bar {
  @extend %rel;
  @extend %pointer;
  width: 25px;
  height: 20px;
  @extend %ml-30;

  span {
    @extend %abs;
    @extend %d-inline-block;
    @extend %w-100;
    height: 3px;
    @include transition($transition);
    background-color: $white-color;
    left: 0;

    &:first-child {
      top: 0;
    }

    &:nth-child(2) {
      top: 52%;
      transform: translateY(-65%);
    }

    &:last-child {
      bottom: 0;
    }
  }

  &.active {
    span {
      &:first-child {
        @include transform(rotate(45deg) translate(3px, 9px));
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        @include transform(rotate(-45deg) translate(3px, -9px));
      }
    }
  }

  @include breakpoint(max-sm) {
    width: 15px;
    height: 16px;

    span {
      height: 2px;
      width: 20px;
    }

    &.active {
      span {
        &:first-child {
          @include transform(rotate(45deg) translate(4px, 6px));
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          @include transform(rotate(-45deg) translate(4px, -6px));
        }
      }
    }
  }
}

.menu-item-has-children {
  > a {
    @extend %rel;
    &.active {
      &::after,
      &::before {
        background-color: $white-color !important;
      }
    }
    &::after {
      @extend %abs;
      top: 50%;
      transform: translateY(-50%);
      right: 25px;
      width: 10px;
      height: 2px;
      background-color: $white-color;
    }

    &::before {
      @extend %abs;
      top: 50%;
      transform: translateY(-50%);
      right: 29px;
      width: 2px;
      height: 10px;
      background-color: $white-color;
      @include transition($transition);
    }

    @include breakpoint(lg) {
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 10px;
        height: 2px;
        background-color: $white-color;
      }

      &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
        width: 2px;
        height: 10px;
        background-color: $white-color;
        @include transition($transition);
      }
    }
  }

  &:hover {
    > a {
      &:before {
        transform: rotate(90deg);
        opacity: 0;
      }
    }
  }

  &.open {
    > a {
      &:before {
        transform: rotate(90deg);
        opacity: 0;
      }
    }
  }
}

.header-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  @extend %flex;
  justify-content: center;
  align-items: center;
  @include transition($transition);

  &.active {
    visibility: visible;
    opacity: 1;
  }

  -color-lay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: $white-color, $alpha: 0.8);

    .cross {
      position: absolute;
      color: $white-color;
      cursor: pointer;
      right: 15px;
      top: 15px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: $theme-color;
    }
  }

  .form-container {
    margin: 15px;
    max-width: 700px;
    width: 100%;
    @extend %flex;
    padding: 30px;
    background: transparent;
    position: relative;
    z-index: 1;

    button {
      width: 120px;
      height: 60px;
      background: $theme-color;
      border: none;
      outline: none;
      font-weight: 700;
      color: $white-color;
      text-transform: uppercase;
    }

    input {
      width: calc(100% - 120px);
      height: 60px;
      border: none;
      outline: none;
      padding: 20px;
    }

    @include breakpoint(max-sm) {
      padding: 1px;
      margin: 10px;

      button {
        width: 100px;
        font-size: 14px;
        font-weight: 400;
      }

      input {
        width: calc(100% - 100px);
      }
    }
  }
}

/*================*** Header Section End Here     ***===================*/

// page header section start here
.page-header-section {
  &.style-1 {
    background-position: center !important;
    background-size: cover !important;
    @extend %p-0;
    @extend %m-0;
    @extend %hidden;
    border: none;
    padding-top: 200px;
    padding-bottom: 60px;
    @extend %text-center;

    @include breakpoint(lg) {
      padding-top: 280px;
      padding-bottom: 100px;
    }

    .page-header-content {
      .page-header-inner {
        .page-title {
          h2 {
            @extend %text-regular;
          }
        }

        .breadcrumb {
          @extend %pt-0;
          @extend %pl-0;
          @extend %justify-center;

          li {
            color: $white-color;
            margin: 0 5px;
            text-transform: capitalize;

            &.active {
              @extend %text-regular;
              @extend %ml-10;
            }

            a {
              color: $white-color;
              @extend %text-bold;
              @extend %rel;
              padding: 0 5px;
              outline: none;
              @include transition($transition);

              &:hover {
                color: $theme-color;
              }

              &::before {
                background: $white-color;
                @extend %abs;
                width: 8px;
                height: 1px;
                transform: translateY(-50%);
                top: 60%;
                right: -10px;
                @include transition($transition);
              }
            }

            &:first-child {
              margin-left: 0;
            }
          }
        }

        .page-title {
          .title-text {
            font-size: $fs-h4;
            color: $white-color;
            text-transform: capitalize;
            @extend %text-bold;

            @include breakpoint(md) {
              font-size: 3.5rem;
              line-height: 4.125rem;
            }

            span {
              color: $theme-color;
            }
          }

          p {
            color: $white-color;
            font-size: $fs-h6;
            text-transform: capitalize;
            @extend %mb-0;
          }
        }
      }
    }
  }
}
